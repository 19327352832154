import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PotentialVendorService {
  constructor(private http: HttpClient) {}

  /**
   * get merchandise list by simple search and advanced search
   */

  getPotentialVendorSearchReslt(
    payload: any,
    page: number,
    size: number
  ): Observable<any[]> {
    //payload.startSDate = payload.startSDate
    let format = 'yyyy-MM-dd';
    if (payload.startDate)
      payload.startDate = formatDate(payload.startDate, format, 'en-US');
    if (payload.endDate)
      payload.endDate = formatDate(payload.endDate, format, 'en-US');
    let qryParam = '?page=' + page + '&size=' + size;
    return this.http.post<any>(
      environment.marketingmanagementapi +
        'custommarketing/potentialvendoradvsearch' +
        qryParam,
      payload
    );
  }

     /**
   * To get  potential vendor  Details
   * @param payload
   */

      checkPotentialVendorDetails(payLoad) {
        return this.http.post<any>(environment.marketingmanagementapi + 'potentialvendor/search' ,payLoad);
      }


    /**
   * To get  potential vendor  Details
   * @param payload
   */

  getPotentialVendorDetails(vendorId) {
    return this.http.get<any>(environment.marketingmanagementapi + 'potentialvendor/' + vendorId);
  }

  /**
   * To save  potential vendor  Details
   * @param payload
   */
  savePotentialVendorDetails(payload) {
    // payload.createdDate = formatDate(payload.createdDate, 'yyyy-MM-dd', 'en-US') ;
    return this.http.post<any>(
      environment.marketingmanagementapi + 'potentialvendor',
      payload
    );
  }
  /**
   * To save  potential vendor  Details
   * @param payload
   */
   updatePotentialVendorDetail(id , payload) {
    // payload.createdDate = formatDate(payload.createdDate, 'yyyy-MM-dd', 'en-US') ;
    return this.http.put<any>(
      environment.marketingmanagementapi + 'potentialvendor/'+id,
      payload
    );
  }

  
  updatePotentialVendorDetails(payload) {
    payload['returnTotal'] = 0;
    payload['paidTotal'] = 0;
    payload.createdDate = formatDate(payload.createdDate, 'yyyy-MM-dd', 'en-US') ;
    payload.dueDate = formatDate(payload.dueDate, 'yyyy-MM-dd', 'en-US') ;
    payload.receivedDate = formatDate(payload.receivedDate, 'yyyy-MM-dd', 'en-US') ;
    return this.http.post<any>(environment.payablesmanagementapi + 'custommerchandise/merchvendorupdate', payload);
  }


    //**
  //To fetch all attachments in notes
  // */
  getAllAttachments(reqData) {
    return this.http.post<any>(environment.marketingmanagementapi + 'potentialvendorattachment/search' , reqData);
  }
  //**
  //To add new  attachment in note
  // */
  saveAttachment(reqData) {
    return this.http.post<any>(environment.marketingmanagementapi + 'potentialvendorattachment/saveAll', reqData);
  }

  //**
  //To update all attachment in  notes
  // */

  updateAttachment(id, reqData) {
    return this.http.put<any>(environment.marketingmanagementapi + 'potentialvendorattachment/' + id, reqData);
  }


   //**
  //To delete one attachment in notes
  // */
  deleteAttachment(selectedItem) {
    const id = parseInt(selectedItem.id);
    return this.http.delete<any>(environment.marketingmanagementapi + 'potentialvendorattachment/' + id);
  }
   //**
  //To delete one attachment in notes
  // */
  deletePotentialVendor(selectedItem) {
    const id = parseInt(selectedItem.id);
    return this.http.delete<any>(environment.marketingmanagementapi + 'potentialvendor/' + id);
  }

  potentialCustomerReport(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.marketingmanagementapi + 'custommarketing/potentialCustomereport', payload);
  }

  potentialVendorReport(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.marketingmanagementapi + 'custommarketing/potentialVendoreport', payload);
  }



}
