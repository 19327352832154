import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private http: HttpClient) { }

  save(vendorPayLoad: any) {
    return this.http.post<any>(environment.usrmanagementapi + 'vendorData', vendorPayLoad);
  }
  getAll() {
    return this.http.get<any>(environment.usrmanagementapi + 'vendorData');
  }
  update(vendorId: any, vendorPayLoad: any) {
    return this.http.put<any>(environment.usrmanagementapi + 'vendorData/' + vendorId, vendorPayLoad);
  }
  searchVendors(requestDTO: any): Observable<any[]> {
    return this.http.post<any>(environment.usrmanagementapi + 'vendorData/search' , requestDTO);
  }

  searchprintVendorDetails(requestDTO: any): Observable<any[]> {
    return this.http.post<any>(environment.usrmanagementapi + 'customcustomer/createQueryForPrintVendors' , requestDTO);
  }

  getVendorLists(requestDTO: any, page: any, size: any): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('size', size);
    return this.http.post<any>(environment.usrmanagementapi + 'customcustomer/advvendorsearch?' + params , requestDTO);
  }

  deleteVendor(id: number): Observable<any[]> {
    return this.http.delete<any>(environment.usrmanagementapi + 'vendorData/' + id);
  }
  getvendorbyid(vendorNo: string): Observable<any> {
    return this.http.get<any>(environment.usrmanagementapi + 'vendorData/' + vendorNo);
  }
  
}
