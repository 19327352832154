import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventorystoneService {

 
  constructor(private http: HttpClient) { }

  getAllStones(itemCode: any): Observable<any[]> {
    
    return this.http.get<any>(environment.inventorymanagementapi + 'custommanfstone/' +encodeURIComponent(itemCode)  );
  }
  getStonesforAppraisal(itemCode: any): Observable<any[]> {
    
    return this.http.get<any>(environment.inventorymanagementapi + 'custommanfstone/getstonesforappraisal/' +encodeURIComponent(itemCode)  );
  }
}
