import { HttpClient, HttpParams } from '@angular/common/http';
import { identifierModuleUrl } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminmaintenanceService {

  constructor(private http: HttpClient) { }
  getAllUsers(): Observable<any[]> {
    return this.http.get<any>(environment.usrmanagementapi + 'pass/');
  }
  searchUsers(requestDTO: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.usrmanagementapi + 'customcustomer/userAdvSearch' + qryParam, requestDTO);
  }
  save(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.usrmanagementapi + 'brands', payload);
  }
  saveUser(userPayload: any): Observable<any[]> {
    return this.http.post<any>(environment.usrmanagementapi + 'pass', userPayload);
  }

  /*********************AP 309 ***************************** */
  getSubModule(submodule: string): Observable<any[]> {
    return this.http.get<any>(environment.usrmanagementapi + 'moduleoption/' + submodule);
  }
  getSubModulePermissions(passAccessListRequestDTO: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.usrmanagementapi + 'passaccess/search' + qryParam, passAccessListRequestDTO);
  }
  getSubModuleByModuleName(moduleOotionListRequestDTO: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.usrmanagementapi + 'moduleoption/search' + qryParam, moduleOotionListRequestDTO);
  }
  getUserById(userId: string): Observable<any[]> {
    return this.http.get<any>(environment.usrmanagementapi + 'pass/' + userId);
  }
  //withoutstatus
  getAllLocations(): Observable<any[]> {
    return this.http.get<any>(environment.usrmanagementapi + 'codeslocation');
  }
  updateUser(userId: string, userPayload: any) {
    return this.http.put<any>(environment.usrmanagementapi + 'pass/' + userId, userPayload);
  }
  savePermission(userPayload: any) {
    return this.http.post<any>(environment.usrmanagementapi + 'passaccess', userPayload);
  }
  getEmployee(requestDTO: any): Observable<any[]> {
    return this.http.post<any>(environment.usrmanagementapi + 'employee/search', requestDTO);
  }
  deleteRolePermission(id: string): Observable<any[]> {
    return this.http.delete<any>(environment.usrmanagementapi + 'passaccess/' + id);
  }
  saveUserLocation(userPayload: any) {
    return this.http.post<any>(environment.usrmanagementapi + 'userlocations', userPayload);
  }
  getEmployeeById(empId: string): Observable<any[]> {
    return this.http.get<any>(environment.usrmanagementapi + 'employee/' + empId);
  }
  getUserLocation(userPayload: any) {
    return this.http.post<any>(environment.usrmanagementapi + 'userlocations/search', userPayload);
  }
  deleteUserLocation(id: number) {
    return this.http.delete<any>(environment.usrmanagementapi + 'userlocations/' + id);
  }

  saveAllPermission(userPayload: any) {
    return this.http.post<any>(environment.usrmanagementapi + 'custompassaccesslist/saveAll', userPayload);
  }

  deleteAllPermission(userPayload: any) {
    return this.http.post<any>(environment.usrmanagementapi + 'custompassaccesslist/deleteAll', userPayload);
  }
  deleteUser(userId: string) {
    return this.http.delete<any>(environment.usrmanagementapi + 'pass/' + userId);
  }
  /****************************************************** */
  updatePassword(userId: string, password: string, oldPass: string) {
    let qryParam = "?oldPassword=" + oldPass + "&newPassword=" + password + "&userId=" + userId;
    return this.http.get<any>(environment.usrmanagementapi + 'customemployee/changepassword' + qryParam);
  }
  updatePasswordbyadmin(userId: string, password: string) {
    let qryParam = "?newPassword=" + password + "&userId=" + userId;
    return this.http.get<any>(environment.usrmanagementapi + 'customemployee/updatepassword' + qryParam);
  }

  searchFaq(requestDTO: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.usrmanagementapi + 'customemployee/faqadvSearch' + qryParam, requestDTO);
  }

  saveFaq(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.usrmanagementapi + 'faq', payload);
  }

  updateFaq(id: any, payload: any) {

    return this.http.put<any>(environment.usrmanagementapi + 'faq/' + id, payload);
  }

  getFaqById(id: any): Observable<any[]> {
    return this.http.get<any>(environment.usrmanagementapi + 'faq/' + id);
  }

  getUserByIdWithoutStatus(userId: string): Observable<any[]> {
    return this.http.get<any>(environment.usrmanagementapi + 'pass/withoutstatus/' + userId);
  }

  copyRole(userId: string, newUserid: string): Observable<any[]> {
    return this.http.get<any>(environment.usrmanagementapi + 'custompassaccesslist/copyRole/' + newUserid + "/" + userId);
  }
  deleteRole(userId: string): Observable<any[]> {
    return this.http.delete<any>(environment.usrmanagementapi + 'custompassaccesslist/deleteRole/' + userId);
  }

  updateLastLoggedIn(userId: string): Observable<any[]> {
    return this.http.get<any>(environment.usrmanagementapi + 'customcustomer/updateLastLoogedIn/' + userId);
  }
}
