import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  searchAdvancePayment(payload: any, page: number, size: number): Observable<any[]> {
    
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.receivablemanagementapi + 'custompayment/paymentadvsearch' + qryParam, payload);
  }

  searchNewPayment(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.receivablemanagementapi + 'custompayment/paymentnewsearch' + qryParam, payload);
  }

  getPaymentTranadvSearch(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.receivablemanagementapi + 'custompayment/paymenttransactionsearch' + qryParam, payload);
  }

  onlinePaymentSearch(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.receivablemanagementapi + 'custompayment/searchViewUnsettledPayments' + qryParam, payload);
  }

  getInvoiceOfunSettledPayments(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.receivablemanagementapi + 'temppaidinvoices/search', payload);

  }

  getpaymentbyId(id: string): Observable<any> {
    return this.http.get<any>(environment.receivablemanagementapi + 'temppayment/' + id);
  }

  updateTempPayment(id: string, payload: any): Observable<any> {
    return this.http.put<any>(environment.receivablemanagementapi + 'temppayment/' + id, payload);
  }

  settlePayment(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.receivablemanagementapi + 'custompayment/settlePayment', payload);
  }

  updateBouncedCheck(payload: any): Observable<any> {
    return this.http.post<any>(environment.receivablemanagementapi + 'custompayment/bouncedCheck', payload);
  }

  getpaymentDetails(paymentNo: String): Observable<any> {

    return this.http.get<any>(environment.receivablemanagementapi + 'pointpaidtrans/' + paymentNo);
  }
  voidPayment(paymentNo: string): Observable<any> {

    return this.http.get<any>(environment.receivablemanagementapi + 'custominvoice/voidCustomerPayment?paymentNo=' + paymentNo);
  }

  getpaidInvoiceDetails(paymentNo: string): Observable<any[]> {

    return this.http.get<any>(environment.receivablemanagementapi + 'custompayment/paymentDetailsOfAPayment?paymentNo=' + paymentNo);
  }

  updatePayment(paymentNo: string, payLoad: any): Observable<any[]> {
    return this.http.put<any>(environment.receivablemanagementapi + 'pointpaidtrans/' + paymentNo, payLoad);
  }

  savePayment(payLoad: any): Observable<any> {
    return this.http.post<any>(environment.receivablemanagementapi + 'custompayment/savepayment', payLoad);
  }
  paymentCreditNotedetails(custNo: any, creditNo: any): Observable<any[]> {
    let qryParam = "?custNo=" + custNo + "&creditNo=" + creditNo;
    return this.http.get<any>(environment.receivablemanagementapi + 'custompayment/paymentcreditNotedetails' + qryParam);
  }
  saveCommonAttachment(payload: any): Observable<any> {
    return this.http.post<any>(environment.payablesmanagementapi + 'commonattachment', payload);
  }

  updateCommonAttachment(id: string, payload: any): Observable<any> {
    return this.http.put<any>(environment.payablesmanagementapi + 'commonattachment/' + id, payload);
  }

  getCommonAttachment(payload: any): Observable<any> {
    return this.http.post<any>(environment.payablesmanagementapi + 'commonattachment/search', payload);
  }
  getCreditCardDetails(payLoad: any): Observable<any[]> {
    return this.http.post<any>(environment.receivablemanagementapi + 'creditcarddata/search', payLoad);
  }
}
