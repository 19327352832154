import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckService {

  constructor(private http: HttpClient) { }

  getAllCheckdata(): Observable<any[]> {
    return this.http.get<any>(environment.payablesmanagementapi + 'checkdata');
  }
  getCheckdataById(id: String): Observable<any> {
    return this.http.get<any>(environment.payablesmanagementapi + 'checkdata/' + id);
  }
  save(payload): Observable<any[]> {
    return this.http.post<any>(environment.payablesmanagementapi + 'checkdata', payload);
  }
  searchCheck(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.payablesmanagementapi + 'checkdata/search', payload);
  }
  searchCheckData(checkNo:any,custNo:any): Observable<any[]> {
    let qryParam = "?checkNo=" + checkNo + "&custNo=" + custNo;

    return this.http.get<any>(environment.payablesmanagementapi + 'customcheck/findCheckByCheckNoAndCustNoWithoutBouncedAndVoidOfACustomer' +qryParam );
  }
  getCheckadvsearchResult(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.payablesmanagementapi + 'customcheck/checkadvsearch' + qryParam, payload);
  }
  deleteCheckById(id: any) {
    return this.http.delete<any>(environment.payablesmanagementapi + 'checkdata/' + id);
  }

  chackStatusChange(payload: any): Observable<any> {
    return this.http.post<any>(environment.receivablemanagementapi + 'custompayment/postACheck', payload);
  }
}



