import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PotentialcustomerService {

  constructor(private http: HttpClient) { }

  potentialCustomerList(payload: any, page: any, size: any): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('size', size);
    return this.http.post<any>(environment.marketingmanagementapi + 'custommarketing/potentialcustomeradvsearch?' + params, payload);
  }

  getPotentialCustomer(custNo: number): Observable<any> {
    return this.http.get<any>(environment.marketingmanagementapi + 'potentialcustomer/' + custNo);
  }

  potentialCustomerSearch(payload: any): Observable<any> {
    return this.http.post<any>(environment.marketingmanagementapi + 'potentialcustomer/search', payload);
  }

  potentialCustomersave(payload: any): Observable<any> {
    return this.http.post<any>(environment.marketingmanagementapi + 'potentialcustomer', payload);
  }

  potentialCustomerupdate(payload: any, custNo: number): Observable<any> {
    return this.http.put<any>(environment.marketingmanagementapi + 'potentialcustomer/' + custNo, payload);
  }

  potentialCustomerdelete(custNo: number): Observable<any> {
    return this.http.delete<any>(environment.marketingmanagementapi + 'potentialcustomer/' + custNo);
  }

  getpotentialCustomerBrand(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.marketingmanagementapi + 'potentialcustomerbrands/search', payload);
  }

  savepotentialCustomerBrand(payload: any): Observable<any> {
    return this.http.post<any>(environment.marketingmanagementapi + 'potentialcustomerbrands', payload);
  }
  updatepotentialCustomerBrand(id: number, payload: any): Observable<any> {
    return this.http.put<any>(environment.marketingmanagementapi + 'potentialcustomerbrands/' + id, payload);
  }

  potentialCustomerGroupsave(payload: any): Observable<any> {
    return this.http.post<any>(environment.marketingmanagementapi + 'potentialcustomergroup', payload);
  }

  getpotentialCustomerGroup(payload: any): Observable<any> {
    return this.http.post<any>(environment.marketingmanagementapi + 'potentialcustomergroup/search', payload);
  }

  potentialCustomerGroupupdate(payload: any, id: number): Observable<any> {
    return this.http.put<any>(environment.marketingmanagementapi + 'potentialcustomergroup/' + id, payload);
  }

  potentialCustomerGroupdelete(id: number): Observable<any> {
    return this.http.delete<any>(environment.marketingmanagementapi + 'potentialcustomergroup/' + id);
  }
}
