import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemoService {

  constructor(private http: HttpClient) { }

  Search(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.receivablemanagementapi + 'custommemo/memoadvsearch' + qryParam, payload);
  }
  getMemoByMemoNo(memoNo: string): Observable<any[]> {
    return this.http.get<any>(environment.receivablemanagementapi + 'memoMaster/' + memoNo);
  }
  checkmemoForCreateReturn(memoNo: string): Observable<any[]> {
    return this.http.get<any>(environment.receivablemanagementapi + 'custommemo/findbalancepiecesinmemo?memoNo=' + memoNo);
  }

  getMemoCatalog(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.receivablemanagementapi + 'custommemo/memocatalog', payload);
  }
}
