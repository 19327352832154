import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VendorStateService {

  constructor(private http: HttpClient) { }

  save(vendorState: any) {
    return this.http.post<any>(environment.usrmanagementapi + 'VenderStateCodes', vendorState);
  }
  deleteStates(statecode): Observable<any[]> {
    return this.http.delete<any>(environment.usrmanagementapi + 'VenderStateCodes/' + statecode);
  }
  getAll() {
    return this.http.get<any>(environment.usrmanagementapi + 'VenderStateCodes');
  }
  getVendorStateById(vendorStateId: any) {
    return this.http.get<any>(environment.usrmanagementapi + 'VenderStateCodes/' + vendorStateId);
  }
  update(vendorStateId: any, vendorState: any) {
    return this.http.put<any>(environment.usrmanagementapi + 'VenderStateCodes/' + vendorStateId, vendorState);
  }
  search(vendorState: any) {
    return this.http.post<any>(environment.usrmanagementapi + 'VenderStateCodes/search', vendorState);
  }
  deleteVendorState(vendorStateId: any) {
    return this.http.delete<any>(environment.usrmanagementapi + 'VenderStateCodes/' + vendorStateId);
  }
}
