import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ControlsRoutingModule } from './controls-routing.module';
import { MaterialcodesComponent } from './materialcodes/materialcodes.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@directives/directives.module';
import { MaterialcodeseditComponent } from './materialcodes/materialcodesedit/materialcodesedit.component';
import { MaterialcodesnewComponent } from './materialcodes/materialcodesnew/materialcodesnew.component';
import { ControlsComponent } from './controls.component';


@NgModule({
  declarations: [ControlsComponent],
  imports: [
    CommonModule,
    DirectivesModule,
    ControlsRoutingModule, MatPaginatorModule,
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    MatSelectModule,
    MatSortModule,
    MatSliderModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule
  ]
})
export class ControlsModule { }
