import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  constructor(private http: HttpClient) { }
  getAllmaterials(): Observable<any[]> {
    
    return this.http.get<any>(environment.inventorymanagementapi + 'materialcodes' );
  }
 
  getMaterialsById(materialId:String): Observable<any> {
    
    return this.http.get<any>(environment.inventorymanagementapi + 'materialcodes/'+materialId );
  }
  update(materialId:String,payload): Observable<any> {
    
    return this.http.put<any>(environment.inventorymanagementapi + 'materialcodes/'+materialId ,payload);
  }
    
  save(payload):Observable<any[]> {

    return this.http.post<any>(environment.inventorymanagementapi + 'materialcodes',payload);

}
}
