import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  from: string;
  message: string;
  messageheader: string;
  delete: string;
}
@Component({
  selector: 'app-delete-confirm-dialogue',
  templateUrl: './delete-confirm-dialogue.component.html',
  styleUrls: ['./delete-confirm-dialogue.component.scss']
})
export class DeleteConfirmDialogueComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteConfirmDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public diaData: DialogData,) { }

  ngOnInit(): void {
    console.log(this.diaData)
  }
  pressButton(chose: string) {
    this.dialogRef.close(chose);
  }
}
