import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoldpricingService {

  constructor(private http: HttpClient) { }

  findGoldPrHistory(payload): Observable<any[]> {
    return this.http.post<any>(environment.manufacturemanagementapi + 'goldprhistory/search', payload);
  }
  savegoldPrHistory(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.manufacturemanagementapi + 'goldprhistory', payload);
  }

  // silver
  findSilverPrHistory(payload): Observable<any[]>{
    return this.http.get<any>(environment.manufacturemanagementapi + 'silverpricehistory');
  }

  saveSilverPrHistory(payload): Observable<any[]>{
    return this.http.post<any>(environment.manufacturemanagementapi + 'silverpricehistory', payload);
  }

   // platinum
   findPlatinumPrHistory(payload): Observable<any[]>{
    return this.http.get<any>(environment.manufacturemanagementapi + 'platinumpricehistory');
  }

  savePlatinumPrHistory(payload): Observable<any[]>{
    return this.http.post<any>(environment.manufacturemanagementapi + 'platinumpricehistory', payload);
  }

}
