import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { materialize } from 'rxjs/operators';
import { ControlsComponent } from './controls.component';


const routes: Routes = [
  {
    path: '',
    component: ControlsComponent,
    children: [{

      path: 'materialcodes',
      data: { icon: '', text: 'Material', breadcrumb: 'Material Codes' },
      loadChildren: () => import('../controls').then(m => m.MaterialcodesModule),
       },
       {
        path: 'rankmaintenance',
        data: { icon: '', text: 'rankmaintenance', breadcrumb: 'Rank Maintenance' },
        loadChildren: () => import('../controls').then(m => m.RankmaintenanceModule),

      },
      {
        path: 'colourcodesetup',
        data: { icon: '', text: 'colourcodesetup', breadcrumb: 'Color Code Setup' },
        loadChildren: () => import('../controls').then(m => m.ColourcodesetupModule),

      },
      {
        path: 'unitofmeasure',
        data: { icon: '', text: 'unitofmeasure', breadcrumb: 'Unit Of Measure' },
        loadChildren: () => import('../controls').then(m => m.UnitofmeasureModule),

      },
      {
        path: 'categorydetails',
        data: { icon: '', text: 'categorydetails', breadcrumb: 'Category Details' },
        loadChildren: () => import('../controls').then(m => m.CategorydetailsModule),

      },
      {
        path: 'madein',
        data: { icon: '', text: 'madein', breadcrumb: 'Made In' },
        loadChildren: () => import('../controls').then(m => m.MadeinModule),
      },
      {
        path: 'typedetails',
        data: { icon: '', text: 'typedetails', breadcrumb: 'Type Details' },
        loadChildren: () => import('../controls').then(m => m.TypedetailsModule),
      },
   
      {
        path: 'marketcodemaintenance',
        data: { icon: '', text: 'marketcodemaintenance', breadcrumb: 'Market Code Maintenance' },
        loadChildren: () => import('../controls').then(m => m.MarketcodemaintenanceModule),

      },
      {
        path: 'shapemaintenance',
        data: { icon: '', text: 'shapemaintenance', breadcrumb: 'Shape Maintenance' },
        loadChildren: () => import('../controls').then(m => m.ShapemaintenanceModule),

      },
      {
        path: 'sizemaintenance',
        data: { icon: '', text: 'sizemaintenance', breadcrumb: 'Size Maintenance' },
        loadChildren: () => import('../controls').then(m => m.SizemaintenanceModule),

      },
      {
        path: 'claritymaintenance',
        data: { icon: '', text: 'claritymaintenance', breadcrumb: 'Clarity Maintenance' },
        loadChildren: () => import('../controls').then(m => m.ClaritymaintenanceModule),

      },

      {
        path: 'stoneorigin',
        data: { icon: '', text: 'stoneorigin', breadcrumb: 'Stone Origin' },
        loadChildren: () => import('../controls').then(m => m.StoneoriginModule),

      },
      {
        path: 'claspmaintenance',
        data: { icon: '', text: 'claspmaintenance', breadcrumb: 'Clasp Maintenance' },
        loadChildren: () => import('../controls').then(m => m.ClaspmaintenanceModule),

      },
       {
        path: 'lockingtype',
        data: { icon: '', text: 'lockingtype', breadcrumb: 'Locking Type' },
        loadChildren: () => import('../controls').then(m => m.LockingtypeModule),

      },

      {
        path: 'settingdetails',
        data: { icon: '', text: 'settingdetails', breadcrumb: 'Setting Details' },
        loadChildren: () => import('../controls').then(m => m.SettingdetailsModule),

      },
      
      {
        path: 'stylenames',
        data: { icon: '', text: 'stylenames', breadcrumb: 'Style Names' },
        loadChildren: () => import('../controls').then(m => m.StylenamesModule),

      },

      {
        path: 'collectionkeywords',
        data: { icon: '', text: 'collectionkeywords', breadcrumb: 'Collection Keywords' },
        loadChildren: () => import('../controls').then(m => m.CollectionkeywordsModule),

      },
  ]

  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ControlsRoutingModule { }
