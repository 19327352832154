import { formatDate } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShowService {
  constructor(private http: HttpClient) { }


  saveshowMaster(payload):Observable<any> {

    return this.http.post<any>(environment.marketingmanagementapi + 'showmaster',payload);

   }
  //  update(templateId:any,payload):Observable<any[]> {

  //   return this.http.put<any>(environment.marketingmanagementapi + 'newslettertemplate/'+ templateId,payload);

  //  }
  findShowMasterByCriteria(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.marketingmanagementapi + 'showmaster/search' , payload);
  }
  findShowMasterEmployeeByCriteria(payload: any): Observable<any> {
    return this.http.post<any>(environment.marketingmanagementapi + 'showmasteremployee/search' , payload);
  }
  findShowMasterHotelByCriteria(payload: any): Observable<any> {
    return this.http.post<any>(environment.marketingmanagementapi + 'showmasterhoteldetails/search' , payload);
  }
  getShowMasterById(id: any) {
    return this.http.get<any>(environment.marketingmanagementapi + 'showmaster/' + id);
  }
  getShowMasterEmployeeById(employeeId: any) {
    return this.http.get<any>(environment.marketingmanagementapi + 'showmasteremployee/' + employeeId);
  }
  updateCustomShowMaster(payload, id: any): Observable<any[]> {
    return this.http.put<any>(
      environment.marketingmanagementapi + 'custommarketing/updatecustomshowmaster/' + id,
      payload
    );
  }
  deleteShowMasterById(id:any) {
    return this.http.delete<any>(environment.marketingmanagementapi + 'showmaster/' + id);
  }
  deleteShowMasterEmployeeById(employeeId:any) {
    return this.http.delete<any>(environment.marketingmanagementapi + 'showmasteremployee/' + employeeId);
  }
  // findShowMasterByCriteria(payload: any, page: any, size: any): Observable<any[]> {
  //   let params = new HttpParams();
  //   params = params.append('page', page);
  //   params = params.append('size', size);
  //   return this.http.post<any>(environment.marketingmanagementapi + 'showmaster/search' + params, payload);
  // }
  showAdvSearch(
    payload: any,
    page: number,
    size: number
  ): Observable<any[]> {
    payload.startSDate = payload.startSDate
    let format = 'yyyy-MM-dd';
    if (payload.startDate)
      payload.startDate = formatDate(payload.startDate, format, 'en-US');
    if (payload.endDate)
      payload.endDate = formatDate(payload.endDate, format, 'en-US');
    let qryParam = '?page=' + page + '&size=' + size;
    return this.http.post<any>(
      environment.marketingmanagementapi +
        'custommarketing/showadvsearch' +
        qryParam,
      payload
    );
  }


getEmployeeById(id: any) {
    return this.http.get<any>(environment.usrmanagementapi + 'employee/' + id);
  }

  getLaptopShowDetailsById(laptopShowCode: any) {
    return this.http.get<any>(environment.receivablemanagementapi + 'laptoplaptopshowdetails/' + laptopShowCode);
  }
}
