import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailtemplateService {

  constructor(private http: HttpClient) { }

  getEmailTemplateById(id: any): Observable<any[]> {
    return this.http.get<any>(environment.usrmanagementapi + 'emailtemplate/' + id);
  }
  getAllEmailTemplates(): Observable<any[]> {
    return this.http.get<any>(environment.usrmanagementapi + 'emailtemplate' );
  }
  saveEmailTemplate(payload){
    return this.http.post<any>(environment.usrmanagementapi + 'emailtemplate', payload);
  }
  updateEmailTemplate(payload:any, templateId: any): Observable<any[]> {
    
    return this.http.put<any>(environment.usrmanagementapi + 'emailtemplate/' + templateId, payload);
  }
  deleteEmailTemplate(id: any): Observable<any[]> {
    return this.http.delete<any>(environment.usrmanagementapi + 'emailtemplate/' + id);
  }
}
