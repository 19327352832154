import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LockingService {

  constructor(private http: HttpClient) { }
  getAlllockingTypes(): Observable<any[]> {

    return this.http.get<any>(environment.inventorymanagementapi + 'lockingtype');
  }

  getlockingTypeById(id: String): Observable<any> {

    return this.http.get<any>(environment.inventorymanagementapi + 'lockingtype/' + id);
  }
  update(id: String, payload: any): Observable<any> {

    return this.http.put<any>(environment.inventorymanagementapi + 'lockingtype/' + id, payload);
  }

  save(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'lockingtype', payload);
  }
  search(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'lockingtype/search', payload);
  }
}