import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BrokerServiceService {

  constructor(private http:HttpClient) { }

  getAllBrokers(): Observable<any[]> {
    
    return this.http.get<any>(environment.usrmanagementapi + 'brokers' );
  }
  save(payload):Observable<any[]> {

    return this.http.post<any>(environment.usrmanagementapi + 'brokers',payload);

  }
  update(brokerId:String,payload): Observable<any> {
    
    return this.http.put<any>(environment.usrmanagementapi + 'brokers/'+brokerId ,payload);
  }
  getBrokerById(brokerId:String): Observable<any> {
    
    return this.http.get<any>(environment.usrmanagementapi + 'brokers/'+brokerId );
  }
 
  searchBroker(payload: any, page: number, size: number): Observable<any[]> {
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.usrmanagementapi + 'customcustomer/brokerAdvSearch'+qryParam, payload);
  
  }

  getBrokerList(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.usrmanagementapi + 'brokers/search', payload);
  
  }
}
