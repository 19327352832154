import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { partitionArray } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MerchandiseService {
  private vendorNumber: any;
  public merchandiseDetails: any;
  private merchandiseExistDetails: any;
  selectedOrderList: any = [];

  constructor(private http: HttpClient) { }
  setSelectedPurchaseOrderList(orderList) {
    this.selectedOrderList = orderList;

  }
  getSelectedPurchaseOrderList() {
    return this.selectedOrderList;
  }



  setVendorNumber(vendorNumber) {
    this.vendorNumber = vendorNumber;

  }
  getVendorNumber() {
    return this.vendorNumber;
  }

  setMerchandiseDetails(merchandiseDetails) {
    this.merchandiseDetails = merchandiseDetails;

  }
  getMerchandiseDetails() {
    return this.merchandiseDetails;
  }

  setMerchandiseExistDetails(merchandiseDetails) {
    this.merchandiseExistDetails = merchandiseDetails;

  }
  getMerchandiseExistDetails() {
    return this.merchandiseExistDetails;
  }

  checkInventoryExists(itemCode) {
    return this.http.get<any>(environment.inventorymanagementapi + 'inventory1/' + encodeURIComponent(itemCode) ,);
  }
  /**
 * get merchandise list by simple search and advanced search
 */


  getMerchandiseSearchReslt(payload: any, page: number, size: number): Observable<any[]> {
    //payload.startSDate = payload.startSDate
    let format = "yyyy-MM-dd";
    if (payload.startDate)
      payload.startDate = formatDate(payload.startDate, format, 'en-US');
    if (payload.endDate)
      payload.endDate = formatDate(payload.endDate, format, 'en-US');
    let qryParam = "?page=" + page + "&size=" + size;
    return this.http.post<any>(environment.payablesmanagementapi + 'custommerchandise/merchadvsearch' + qryParam, payload);
  }
  /**
* get merchandise list by simple search and advanced search
*/
  getProductOrdereSearchReslt(payload: any , page: number, size: number) : Observable<any[]> {
    let format = "yyyy-MM-dd";
    let qryParam = "?page=" + page + "&size=" + size;
    if(payload.purchaseDate)
    payload.purchaseDate = formatDate(payload.purchaseDate, format, 'en-US');
    return this.http.post<any>(environment.payablesmanagementapi + 'custompayables/purchaseordersearchformerch' + qryParam, payload);
  }
  /**
 * get expence list dropdown values
 */
  getExpenceList() {
    return this.http.get<any>(environment.usrmanagementapi + 'merchandisecategory');
  }
  /**
   * get shiping via dropdown values
   */
  getShippingViaList() {
    return this.http.get<any>(environment.usrmanagementapi + 'shippingcodes');
  }
  /**
 * get terms via dropdown values
 */
  getTermsList() {
    return this.http.get<any>(environment.usrmanagementapi + 'paymentterms');
  }

  /**
 * get sub category dropdown values
 */
  getSubCategoryList(categoryCode) {
    let payload = {
      "categoryCode": categoryCode
    }
    return this.http.post<any>(environment.usrmanagementapi + 'merchandisesubcategory/search', payload);
  }

  getVendorDetails(payload) {
    return this.http.post<any>(environment.usrmanagementapi + 'vendorData/search', payload);
  }
  getVendorNotificationDetails(payload) {
    return this.http.post<any>(environment.usrmanagementapi + 'vendorNotification/search', payload);
  }

  getVendorNotification(vendorNo: string) {
    return this.http.get<any>(environment.usrmanagementapi + 'vendorNotification/' + vendorNo);
  }


  getVendorDetailsFromPurchase(vendorNo) {
    return this.http.get<any>(environment.usrmanagementapi + 'vendorData/' + vendorNo);
  }
  /**
   * To save  Merchandise Details
   * @param payload 
   */
  saveMerchandiseDetails(payload) {
    payload['returnTotal'] = 0;
    payload['paidTotal'] = 0;
    payload.createdDate = formatDate(payload.createdDate, 'yyyy-MM-dd', 'en-US') ;
    payload.dueDate = formatDate(payload.dueDate, 'yyyy-MM-dd', 'en-US') ;
    payload.receivedDate = formatDate(payload.receivedDate, 'yyyy-MM-dd', 'en-US') ;
    return this.http.post<any>(environment.payablesmanagementapi + 'MerchandiseReceivedData', payload);
  }
  

  updateMerchandiseDetails(payload) {
    payload['returnTotal'] = 0;
    payload['paidTotal'] = 0;
    payload.createdDate = formatDate(payload.createdDate, 'yyyy-MM-dd', 'en-US') ;
    payload.dueDate = formatDate(payload.dueDate, 'yyyy-MM-dd', 'en-US') ;
    payload.receivedDate = formatDate(payload.receivedDate, 'yyyy-MM-dd', 'en-US') ;
    payload.invoiceDate = formatDate(payload.invoiceDate, 'yyyy-MM-dd', 'en-US')
    return this.http.post<any>(environment.payablesmanagementapi + 'custommerchandise/merchvendorupdate', payload);
  }
  /**
 * To save and confirm  Merchandise Details
 * @param payload 
 */
  // saveAndConfirmMerchandiseDetails(payload) {
  //   payload.createdDate = new Date(payload.createdDate);
  //   payload.dueDate = new Date(payload.dueDate);
  //   payload.receivedDate = new Date(payload.receivedDate);
  //   return this.http.post<any>(environment.payablesmanagementapi + 'MerchandiseReceivedData1', payload);
  // }

  //// *************** Line Items ***********////////////////
  checkItemCode(itemCode) {
    return this.http.get<any>(environment.inventorymanagementapi + 'inventory1/' +  encodeURIComponent(itemCode) );
  }

  getVendorDetailsLineItems(payload) {
    return this.http.post<any>(environment.inventorymanagementapi + 'vendoritem/search', payload);
  }
  checkMarchandiseExistItems(payload) {
    return this.http.post<any>(environment.payablesmanagementapi + 'MerchandiseReceivedData/search', payload);
  }
  saveLineItems(payload) {
    return this.http.post<any>(environment.payablesmanagementapi + 'custommerchandise/saveAll', payload);
  }
  updateLineItems(payload) {
    return this.http.put<any>(environment.payablesmanagementapi + 'custommerchandise/updateAll', payload);
  }
  confirm(merchNo) {
    let qryParam = "?mercNo=" + merchNo;
    return this.http.get<any>(environment.payablesmanagementapi + 'custommerchandise/confirmMerch' + qryParam);

  }
  saveAndConfirmLineItems(payload) {
    return this.http.post<any>(environment.payablesmanagementapi + 'custommerchandise/saveAll', payload);
  }
  deleteLineItem(id, vendorNo) {
    return this.http.delete<any>(environment.payablesmanagementapi + 'custommerchandise/' + id + '/' + vendorNo);
  }

  getEditModeMerchandiseDetails(merchNo) {
    return this.http.get<any>(environment.payablesmanagementapi + 'MerchandiseReceivedData/' + merchNo);
  }
  checkMerchandiseExist(payLoad) {
    return this.http.post<any>(environment.payablesmanagementapi + 'MerchandiseReceivedData/search', payLoad);
  }
  getEditModeLineItemsDetails(merchNo) {
    let payload = {
      "merchNo": merchNo
    }
    // return this.http.post<any>(environment.payablesmanagementapi + 'merchandisereceiveditems/search', payload);

    return this.http.post<any>(environment.payablesmanagementapi + 'merchandisereceiveditems/search', payload)
      .pipe(map(result => {
        const items = <any[]>result; // could be SomeItem[] instead of any[]
        items.forEach(item => {
          item['retTotalPrice'] = item?.noPcP * item?.unitPrice;
        });
        return items;
      }));
  }

  getInDirectLineItemsDetails(payload) {

    // return this.http.post<any>(environment.payablesmanagementapi + 'custompayables/purchaseorderitemlisting', payload);

    return this.http.post<any>(environment.payablesmanagementapi + 'custompayables/purchaseorderitemlisting', payload)
      .pipe(map(result => {
        const items = <any[]>result; // could be SomeItem[] instead of any[]
        items.forEach(item => {
          item['retTotalPrice'] = item?.noPcP * item?.unitPrice;
        });
        return items;
      }));
  }

  saveNewInventory(payload: any): Observable<any[]> {

    return this.http.post<any>(environment.payablesmanagementapi + 'custommerchandise/saveMercInventory1', payload);
  }

}
