import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreditCardService {
  
  constructor(private http: HttpClient) { }

  save(creditCardPayload: any) {
    return this.http.post<any>(environment.usrmanagementapi + 'creditcardsnames', creditCardPayload);
  }
  getAll() {
    return this.http.get<any>(environment.usrmanagementapi + 'creditcardsnames');
  }
  getCreditCardById(getCreditCardId: any) {
    return this.http.get<any>(environment.usrmanagementapi + 'creditcardsnames/' + getCreditCardId);
  }
  update(getCreditCardId: any, creditCardPayload: any) {
    return this.http.put<any>(environment.usrmanagementapi + 'creditcardsnames/'+ getCreditCardId, creditCardPayload);
  }
  deleteCreditCardById(CreditCardId: any) {
    return this.http.delete<any>(environment.usrmanagementapi + 'creditcardsnames/' + CreditCardId);
  }
}
