import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ColorService {
 
  constructor(private http: HttpClient) { }

  getAllColor(): Observable<any[]> {
    return this.http.get<any>(environment.inventorymanagementapi + 'colorcodes' );
  }

  getcolorById(colorId:String): Observable<any> {
    
    return this.http.get<any>(environment.inventorymanagementapi + 'colorcodes/'+colorId );
  }

  update(colorId:String,payload): Observable<any> {
    
    return this.http.put<any>(environment.inventorymanagementapi + 'colorcodes/'+colorId ,payload);
  }
  save(payload):Observable<any[]> {

    return this.http.post<any>(environment.inventorymanagementapi + 'colorcodes',payload); 
  }

}
