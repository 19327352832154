<div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content receivables-modal-shadow">
        <div class="modal-header">
            <h5 class="modal-title main-content-head" id="exampleModalLongTitle">
                {{diaData.messageheader==null?'Delete':diaData.messageheader}}</h5>
            <button type="button" (click)="pressButton('no')" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="row modal-body dialogsize">
            <div class="row receivables-setion-main">
                <div class="form-group width-100 position-relative">
                    {{diaData.message}}
                </div>
                <div class="  main-content-mob-save ">
                    <div class="menu-save-close-outer">
                        <div class="common-button button-mrg-right receivables-mob-save-button receivables-mob-save-button-mrg">
                            <a (click)="pressButton('yes')" class="receivables-purple">Yes</a>
                        </div>
                        <div class="common-button receivables-mob-save-button">
                            <a (click)="pressButton('no')" class="button-white receivables-white">No</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>