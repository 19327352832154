import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubkeywordService {

  constructor(private http: HttpClient) { }
  getAllSubkeyword(): Observable<any[]> {

    return this.http.get<any>(environment.inventorymanagementapi + 'collectionsubkeyword');
  }

  getsubkeywordById(subkeyId: String): Observable<any> {

    return this.http.get<any>(environment.inventorymanagementapi + 'collectionsubkeyword/' + subkeyId);
  }
  update(subkeyId: String, payload: any): Observable<any> {
    return this.http.put<any>(environment.inventorymanagementapi + 'collectionsubkeyword/' + subkeyId, payload);
  }

  save(payload: any): Observable<any[]> {
    return this.http.post<any>(environment.inventorymanagementapi + 'collectionsubkeyword', payload);
  }
  
  getsubkeywordbyKeyword(payload: any): Observable<any> {
    return this.http.post<any>(environment.inventorymanagementapi + 'collectionsubkeyword/search/', payload);
  }
}
