<!-- Modal content-->


<div class="modal-header">
    <!-- <h4 class="modal-title text-primary">Copy access from Employee </h4> -->
    <button type="button" class="close pull-right" data-dismiss="modal" (click)="pressButton('no')">×</button>
</div>
<div class="modal-body">
    <div class="table-responsive-dialogbox">
        {{msg}}
    </div>
</div>
<div class="modal-footer margin">
    <div class="common-button button-mrg-right  " >
        <button class="button-golden" (click)="pressButton('yes')"> Yes
        </button>
    </div>
    <div class="common-button">
        <button class="button-white" (click)="pressButton('no')">
            No </button>
    </div>
    
</div>


